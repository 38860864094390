export const aiSummaryTab = () => {
  return {
    summaryElement: null,
    copiedCardBlock: false,

    copyToClipboard(text) {
      navigator.clipboard.writeText(text.innerText);
      this.copiedCardBlock = true;
      let that = this;
      setTimeout(function () {
        that.copiedCardBlock = false;
      }, 3000);
    },
    init() {
      this.summaryElement = document.getElementById("ai-summary-tab");
    },
  };
};
