export const shareVideoModal = () => {
  return {
    shareModalInput: null,
    isShareModalOpen: false,
    isCopyPressed: false,

    copyToClipboard(text) {
      navigator.clipboard.writeText(text.value);
      this.isCopyPressed = true;
      let that = this;
      setTimeout(function () {
        that.isCopyPressed = false;
      }, 3000);
    },
    init() {
      this.shareModalInput = document.getElementById("share-modal-input");
    },
  };
};
