export const transcriptTab = () => {
  return {
    transcript: null,

    init() {
      this.transcript = document.getElementById("transcript-content");
    },
  };
};

export const speechBlockCard = (startTime, endTime) => {
  return {
    copiedSpeechBlock: false,
    showCommands: false,
    isActiveBlock: false,

    init() {
      window.addEventListener("transcriptTimeUpdateHighlight", (event) => {
        const currentTime = event.detail.startTime;
        if (currentTime >= startTime && currentTime < endTime) {
          this.isActiveBlock = true;
          this.scrollToBlock();
        } else {
          this.isActiveBlock = false;
        }
      });
    },
    scrollToBlock() {
      this.$el.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text.innerText);
      this.copiedSpeechBlock = true;
      let that = this;
      setTimeout(function () {
        that.copiedSpeechBlock = false;
      }, 3000);
    },
    setShowCommands() {
      this.showCommands = !this.showCommands;
    },
  };
};
