export const dateFormatter = (dateInIsoString) => {
  const date = new Date(dateInIsoString);
  if (isNaN(date)) {
    return { date: "Invalid Date" };
  }
  const fomattedDate = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
  }).format(date);
  return { date: fomattedDate };
};
