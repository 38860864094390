export const callVideoPlayer = () => {
  return {
    isPlaying: false,
    isVisibleForward: false,
    isVisibleBackward: false,
    currentTime: 0,
    duration: 0,
    currentTimeTxt: "00:00",
    durationTxt: "00:00",
    video: null,
    playbackSpeed: 1,
    isVideoLoading: false,
    isSoundOn: true,
    areCaptionsOn: false,
    transition: "opacity 1s ease",

    init() {
      this.video = document.getElementById("call-video");
    },

    updateCurrentTime() {
      if (this.video) {
        let current = this.video.currentTime;
        this.currentTime = current;
        this.currentTimeTxt = this.formatTime(current);
        window.dispatchEvent(
          new CustomEvent("transcriptTimeUpdateHighlight", {
            detail: { startTime: current },
          }),
        );
      }
    },

    updateDuration() {
      if (this.video) {
        let duration = this.video.duration;
        this.duration = duration;
        this.durationTxt = this.formatTime(duration);
      }
    },

    async play() {
      if (this.video && this.video.paused) {
        await this.video.play();
        this.isPlaying = !this.video.paused;
      }
    },

    async pause() {
      if (this.video && !this.video.paused) {
        await this.video.pause();
        this.isPlaying = !this.video.paused;
      }
    },

    async playPause() {
      if (this.video) {
        if (document.fullscreenElement) {
          this.isPlaying = this.video.paused;
          return;
        }

        if (this.video.paused) {
          return await this.play();
        }

        return await this.pause();
      }
    },

    backward() {
      if (this.video) {
        if (this.video.currentTime - 10 > 0) {
          this.video.currentTime -= 10;
        } else {
          this.video.currentTime = 0;
        }

        this.transition = "opacity 0s";
        this.isVisibleBackward = true;
        setTimeout(() => {
          this.transition = "opacity 1s ease";
          this.isVisibleBackward = false;
        }, 1);
      }
    },

    forward() {
      if (this.video) {
        if (this.video.currentTime + 10 < this.video.duration) {
          this.video.currentTime += 10;
        } else {
          this.video.currentTime = this.video.duration;
        }

        this.transition = "opacity 0s";
        this.isVisibleForward = true;
        setTimeout(() => {
          this.transition = "opacity 1s ease";
          this.isVisibleForward = false;
        }, 1);
      }
    },

    changePlaybackSpeed() {
      if (this.video) {
        const supportedPlaybackSpeedsMap = new Map([
          [0.5, 1],
          [1, 1.25],
          [1.25, 1.5],
          [1.5, 1.75],
          [1.75, 2],
          [2, 2.5],
          [2.5, 0.5],
        ]);

        if (supportedPlaybackSpeedsMap.has(this.playbackSpeed)) {
          this.playbackSpeed = supportedPlaybackSpeedsMap.get(
            this.playbackSpeed,
          );
        } else {
          this.playbackSpeed =
            this.playbackSpeed > 2.5 ? 0.5 : this.playbackSpeed + 0.25;
        }

        this.video.playbackRate = this.playbackSpeed;
      }
    },

    toggleFullScreen() {
      if (!document.fullscreenElement) {
        this.video.requestFullscreen().catch((err) => {
          alert(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`,
          );
        });
      } else {
        document.exitFullscreen();
      }
    },

    soundClicked() {
      if (this.video) {
        if (this.isSoundOn) {
          this.video.volume = 0;
          this.isSoundOn = false;
        } else {
          this.video.volume = 1;
          this.isSoundOn = true;
        }
      }
    },

    updateVolume() {
      if (this.video) {
        const isMuted = this.video.muted;
        const volumeLevel = this.video.volume;
        this.isSoundOn = volumeLevel > 0 && !isMuted;
      }
    },

    updatePlaybackSpeed() {
      if (this.video) {
        this.playbackSpeed = this.video.playbackRate;
      }
    },

    setCurrentTime(event, element) {
      if (this.video) {
        const rect = element.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const percentage = (x / rect.width) * 100;
        const newTime = (percentage / 100) * this.video.duration;
        this.video.currentTime = newTime;
      }
    },
    setTranscriptTime(time) {
      if (this.video) {
        this.video.currentTime = time;
      }
    },
    formatTime(time) {
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time % 3600) / 60);
      let seconds = Math.floor(time % 60);

      if (hours > 0) {
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      } else {
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      }
    },
  };
};
