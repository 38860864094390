import Alpine from "alpinejs";
import { TokenManager } from "./token-manager";
import _htmx from "htmx.org";
import { callVideoPlayer } from "./video-player";
import { speechBlockCard, transcriptTab } from "./transcript-tab";
import { aiSummaryTab } from "./ai-summary-tab";
import { aiCoachTab } from "./ai-coach-tab";
import { dateFormatter } from "./date-formatter";
import { currencyFormatter } from "./currency-formatter";
import { shareVideoModal } from "./share-video-modal";

window.Alpine = Alpine;

const _setCompanyHeader = (company_id) =>
  localStorage.setItem("company_header", company_id);
const _getCompanyHeader = () => localStorage.getItem("company_header");

const tokenManager = new TokenManager();
Alpine.data("tokenManager", (token = undefined) => {
  return {
    manager: tokenManager,
    async signin() {
      await tokenManager.signin();
    },
    async logout() {
      await tokenManager.logout();
    },
    setCompanyHeader(evt) {
      const company_id = evt.target.value;
      _setCompanyHeader(company_id);
    },
  };
});

Alpine.data("callVideoPlayer", callVideoPlayer);
Alpine.data("aiSummaryTab", aiSummaryTab);
Alpine.data("transcriptTab", transcriptTab);
Alpine.data("speechBlockCard", speechBlockCard);
Alpine.data("aiCoachTab", aiCoachTab);
Alpine.data("dateFormatter", dateFormatter);
Alpine.data("currencyFormatter", currencyFormatter);
Alpine.data("shareVideoModal", shareVideoModal);

Alpine.start();
