export class TokenManager {
  constructor() {
    this.refreshTokenUrl = "/refresh-token";
  }

  async kinde() {}

  async signup() {
    const kinde = await this.kinde();
    await kinde.register();
  }

  storeAccessToken(accessToken) {
    localStorage.setItem("accessToken", accessToken);
  }

  accessToken() {
    return localStorage.getItem("accessToken");
  }

  async isAccessTokenValid() {
    const accessToken = await this.accessToken();
    if (!accessToken) return false;

    const payload = JSON.parse(atob(accessToken.split(".")[1]));
    const expires = payload.exp * 1000; // Convert to milliseconds
    return Date.now() < expires;
  }

  async refreshAccessToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      this.redirectToLogin();
      return;
    }

    const response = await fetch(this.refreshTokenUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (response.ok) {
      const data = await response.json();
      this.storeAccessToken(data.accessToken);
    } else {
      this.redirectToLogin();
    }
  }

  async ensureAccessToken() {
    const isAccessTokenValid = await this.isAccessTokenValid();
    if (isAccessTokenValid) {
      console.log("Access token is valid");
    } else {
      console.log("Access token is invalid, refreshing...");
      await this.refreshAccessToken();
    }
  }
}
